import { message } from 'antd';
import axios from 'axios';
import { useCallback, useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { useAuth } from '@unbooking/ui-auth';
import { Header } from './components';
import { TemplatesListPage, EditTemplatePage, CallbackPage, SignInPage, NoAccessPage, ErrorPage } from './pages';
import { LocalStorageKeys } from './common/constants';

const App = () => {
  const navigate = useNavigate();
  const { isAuthenticated, idToken, userEmail } = useAuth();
  const permissionGranted = localStorage.getItem(LocalStorageKeys.PERMISSION_GRANTED);

  const grantAccess = useCallback(() => {
    message.success('Logged in successfully');
    localStorage.setItem(LocalStorageKeys.PERMISSION_GRANTED, 'true');
    navigate('/', { replace: true, state: { permissionGranted: true } });
  }, [navigate]);

  const denyAccess = useCallback(() => {
    navigate('/forbidden', { replace: true });
  }, [navigate]);

  const checkUserPermissions = useCallback(async () => {
    try {
      const { status } = await axios.get(
        `${process.env.REACT_APP_HBH_URL}/api/v2/core/email-templates/check-permissions/`,
        {
          headers: {
            Authentication: idToken || ''
          },
        }
      );

      if (status === 200) {
        grantAccess();
      }
    } catch (err: any) {
      if (err.response && err.response?.status === 403) {
        denyAccess();
      } else {
        navigate('/error', {
          replace: true,
          state: { errorMessage: err.message ?? 'We are sorry! Report to the dev team please' },
        });
      }
    }
  }, [idToken, userEmail, navigate, grantAccess, denyAccess]);

  useEffect(() => {
    if (isAuthenticated && !permissionGranted) {
      checkUserPermissions();
    }
  }, [isAuthenticated, permissionGranted, checkUserPermissions]);

  return (
    <>
      {isAuthenticated && permissionGranted && window.location.pathname !== '/forbidden' ? <Header /> : null}
      <Routes>
        <Route path='/'>
          {isAuthenticated && permissionGranted ? (
            <>
              <Route index element={<TemplatesListPage />} />
              <Route path='email-template' element={<TemplatesListPage />} />
              <Route path='edit-template/:code' element={<EditTemplatePage />} />
              <Route path='add-template' element={<EditTemplatePage createMode={true} />} />
            </>
          ) : (
            <>
              <Route index element={<SignInPage />} />
              <Route path='callback' element={<CallbackPage />} />
              <Route path='forbidden' element={<NoAccessPage />} />
              <Route path='error' element={<ErrorPage />} />
            </>
          )}
          <Route path='*' element={<Navigate replace to='/' />} />
        </Route>
      </Routes>
    </>
  );
};

export default App;
