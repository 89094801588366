import { TAuthConfig } from '@unbooking/ui-auth';
import { Env } from '../constants';

const cognitoDomain =
  process.env.REACT_APP_ENV === Env.PROD
    ? 'https://api-email-template.humanitarianbooking.wfp.org'
    : `https://api-email-template.${process.env.REACT_APP_ENV}.humanitarianbooking.wfp.org`;

export const authConfig: TAuthConfig = {
  authorizationEndpoint: process.env.REACT_APP_AUTH_AUTHORIZATION_ENDPOINT ?? '',
  clientId: process.env.REACT_APP_AUTH_CLIENT_ID ?? '',
  redirectUri: process.env.REACT_APP_AUTH_REDIRECT_URI ?? '',
  tokenEndpoint: process.env.REACT_APP_AUTH_TOKEN_ENDPOINT ?? '',
  logoutEndpoint: process.env.REACT_APP_AUTH_LOGOUT_ENDPOINT ?? '',
  logoutUri: process.env.REACT_APP_AUTH_LOGOUT_URI ?? '',
  tokenExpirationSource: 'access_token',
  tokenBearerSource: 'access_token',
  scope: ['email', 'openid', 'profile', `${cognitoDomain}/read`, `${cognitoDomain}/write`].join(' '),
};
