import { useRef } from 'react';
import Editor from 'jodit-react';
import { editorConfig } from './config';

interface EmailEditorProps {
  value?: string;
  onChange?: (value: string) => void;
}

const EmailEditor = ({ value = '', onChange }: EmailEditorProps) => {
  const editor = useRef(null);

  return (
    <Editor ref={editor} value={value} config={editorConfig} onBlur={(editorContent) => onChange?.(editorContent)} />
  );
};

export default EmailEditor;
